import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatSortModule } from "@angular/material/sort"
import { MatDialogModule } from "@angular/material/dialog"
import { MatSelectModule } from "@angular/material/select"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatCheckboxModule } from "@angular/material/checkbox"
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HomeComponent } from './components/home/home.component';
import { DataTablesModule } from 'angular-datatables';
import { DatePipe, NgFor } from '@angular/common';
import { InterceptorInterceptor } from './interceptor/interceptor.interceptor';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatChipsModule } from '@angular/material/chips';
import { WebcamModule } from 'ngx-webcam';
import { HeaderComponent } from './components/header/header.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RegistrationComponent } from './components/registration/registration.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { UserlistComponent } from './components/userlist/userlist.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RealTimeComponent } from './components/real-time/real-time.component';
import { AutoAllocationComponent } from './components/auto-allocation/auto-allocation.component';
import { EraseDeviceComponent } from './components/erase-device/erase-device.component';
import { AllocatedDeviceComponent } from './components/allocated-device/allocated-device.component';
import { DeallocateDeviceComponent } from './components/deallocate-device/deallocate-device.component';
import { ManualPortalAllocationComponent } from './components/manual-portal-allocation/manual-portal-allocation.component';
import { PortalTransferComponent } from './components/portal-transfer/portal-transfer.component';
import { ManualPortalAllocationListComponent } from './components/manual-portal-allocation-list/manual-portal-allocation-list.component';
import { MatIconModule } from '@angular/material/icon';
import { PortalTransferListComponent } from './components/portal-transfer-list/portal-transfer-list.component';
import { ChildSelectPortalComponent } from './components/child-select-portal/child-select-portal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    RegistrationComponent,
    AuthenticationComponent,
    UserlistComponent,
    ForgotComponent,
    ResetPasswordComponent,
    RealTimeComponent,
    AutoAllocationComponent,
    EraseDeviceComponent,
    AllocatedDeviceComponent,
    DeallocateDeviceComponent,
    ManualPortalAllocationComponent,
    PortalTransferComponent,
    ManualPortalAllocationListComponent,
    PortalTransferListComponent,
    ChildSelectPortalComponent
  ],
  imports: [
    MatInputModule,
    MatIconModule,
    ToastrModule.forRoot(),
    MatCardModule,
    MatChipsModule,
    MatRadioModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    NgxUiLoaderModule,
    // NgxUiLoaderHttpModule.forRoot({
    //   showForeground: true,
    // }),
    WebcamModule,
    MatAutocompleteModule,
    BsDatepickerModule.forRoot(),
    MatNativeDateModule,
    MatDatepickerModule,
    NgFor,
    FontAwesomeModule
  ],
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
