<app-header></app-header>
<section class="header">
    <div class="container">
        <div class="header-text">
            <a routerLink="/auto-allocate">
                <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
            </a>
            <h2>Customer Allocations</h2>
            <a href="#">
                <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
            </a>
        </div>
    </div>
</section>
<section class="table">
    <div class="container">
        <span class="table-top">Allocated Devices</span>
        <div class="inner-table table-responsive">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                class="mat-elevation-z8">

                <!-- Position Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;">{{element.ID}}</td>
                </ng-container>

                <ng-container matColumnDef="device_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by device_id">
                        IMEI
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.device_id}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
                        Time
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</section>