import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-child-select-portal',
  templateUrl: './child-select-portal.component.html',
  styleUrls: ['./child-select-portal.component.scss']
})
export class ChildSelectPortalComponent {

  allPoratlData: any[] = [];
  allPortalNames = new FormControl();
  filteredOptions: Observable<any[]> | undefined;
  portalId: any;
  @Output() valueChange = new EventEmitter();

  constructor(private api: ApiService, private toastrService: ToastrService, private ngxLoader: NgxUiLoaderService, private router: Router) { }

  ngOnInit() {
    this.getAllPoratlData()
    this.filteredOptions = this.allPortalNames.valueChanges.pipe(startWith(''), map((value: string) => this._filter(value)));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allPoratlData.filter(option => option.id_name.toLowerCase().includes(filterValue));
  }

  getAllPoratlData() {
    this.ngxLoader.start();
    this.api.getRequest('real-time').subscribe(item => {
      this.allPoratlData = item;
      const activeItems = item.filter((item: any) => item.isAutoAllocationActive === true);
      this.ngxLoader.stop();
      // console.log(activeItems.length);
      if (activeItems.length > 0) {
        Swal.fire({
          allowOutsideClick: false,
          icon: "info",
          title: "Oops...",
          text: "Stopping Automatic Allocation of New TracNet Devices!",
          confirmButtonText: "Click here?",
          confirmButtonColor: '#3085d6',
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['auto-allocate']);
          }
        });
      }
    })
  }

  myPortalNames(event: any) {
    const filteredData = this.allPoratlData.filter(item => item.id_name === event);
    if (filteredData.length > 0) {
      this.portalId = filteredData[0]._id
      this.valueChange.emit(this.portalId);
    } else {
      this.portalId = '';
    }
  }

  onMouseEnter() {
    this.allPortalNames.setValue('');
  }

}

