import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { isNumber } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
  selector: 'app-manual-portal-allocation-list',
  templateUrl: './manual-portal-allocation-list.component.html',
  styleUrls: ['./manual-portal-allocation-list.component.scss']
})
export class ManualPortalAllocationListComponent {
  faInfoCircle = faInfoCircle;
  portalId: any;
  originalTimeZone = "UTC";
  targetTimeZone = "Australia/Brisbane";
  selectAll: boolean = false;
  resultData: any[] = [];
  displayedColumns: string[] = ['ID', 'device_id', 'date'];
  displayedColumns2: string[] = ['Portal', 'device_id2', 'date2'];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();
  isShown: boolean = false;
  existingDevices: any;
  isButtonDisabled: boolean = true; // Initially, button is enabled
  resultData2: any[] = [];
  checkedValues!: any[];
  @ViewChild('hiddenInput') hiddenInput: any;

  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private ngxLoader: NgxUiLoaderService, private router: Router) { }
  @ViewChild(MatSort) sort!: MatSort;

  tooltipText = 'File content must be a text file where each line contains a TracNet IMEI';

  onChanges(event: any) {
    this.portalId = event
  }

  ngAfterViewInit() {
    this.dataSource2.sort = this.sort;
  }

  toggleVisibility(): void {
    this.isShown = !this.isShown;
  }

  closeModal(): void {
    this.isShown = !this.isShown;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  announceSortChange2(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getUnallocatedDevices(item: any) {

    const responce = item;
    const result = responce.map((items: any, index: any) => {
      return { ...items, ID: (index + 1).toString() };
    });
    const resultArray = result.map((itemss: any) => {
      return { ...itemss, date: this.formatDate(itemss.date) };
    });
    this.resultData = resultArray.sort((a: any, b: any) => {

      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();

      return dateB - dateA;
    });

    this.dataSource = new MatTableDataSource(this.resultData);
    this.dataSource.sort = this.sort;
  }

  getExistingDevicesWithPortal(item: any) {

    const responce = item;
    console.log(responce, "responce")
    const result = responce.map((items: any) => {
      return { ...items, device_id2: items.device_id };
    });
    const resultArray = result.map((itemss: any) => {
      return { ...itemss, date2: this.formatDate(itemss.date) };
    });
    this.resultData2 = resultArray.sort((a: any, b: any) => {

      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();

      return dateB - dateA;
    });
    this.dataSource2 = new MatTableDataSource(this.resultData2);
    this.dataSource2.sort = this.sort;
  }

  private formatDate(dateString: Date) {

    const originalDate = moment(dateString, 'YYYY-MM-DDTHH-mm-ss');
    const brisbaneTime = originalDate.tz('Australia/Brisbane');
    const formattedBrisbaneTime = brisbaneTime.format('YYYY-MM-DD HH:mm:ss');
    return formattedBrisbaneTime
  }

  public GetFileOnLoad(event: any) {
    var file = event.target.files[0];
    if (file) {
      this.readFile(file);
    }
    var element = document.getElementById("fakeFileInput") as HTMLInputElement | null;
    if (element != null) {
      element.value = file?.name;
    }
  }
  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const fileContent: string = reader.result as string;
        const dataArray = fileContent.split('\n').map((line: string) => {
          const trimmedLine = line.replace(/\s/g, "");
          if (trimmedLine.length !== 15) {

            Swal.fire({
              position: "center",
              icon: "error",
              title: "IMEI's Data Is Not In Correct Format.",
              showConfirmButton: true,
              timer: 3000
            });
            throw new Error("Each string should have exactly 15 characters.");
          }
          return trimmedLine;
        });

        this.ngxLoader.start();
        let payload = { device_ids: dataArray };

        this.api.postRequest('manual-allocation/get-devices-by-file', payload).subscribe(
          item => {
            console.log(item, "item")
            if (item.unallocatedDevicesData.length == 0) {
              Swal.fire({
                position: "center",
                icon: "info",
                title: "Record Not Found.",
                showConfirmButton: false,
                timer: 3000
              }).then((result) => {
                if (result.isDismissed) {

                }
              });
            }

            this.ngxLoader.stop();
            this.getUnallocatedDevices(item.unallocatedDevicesData)
            this.getExistingDevicesWithPortal(item.existingData)
            this.existingDevices = item.existingData.length;
          },

          error => {
            this.ngxLoader.stop();
            console.error("Error occurred while processing the API request:", error);
          }
        );
      } catch (error) {
        console.error("Error occurred while processing the file:", error);
      }
    };
    reader.onerror = (event) => {
      console.error("File reading error:");
    };

    reader.readAsText(file);
  }
  onCheckboxChange(element: any) {
    const checkedItems = this.resultData.filter((item: any) => item.checked);
    this.checkedValues = checkedItems.map((item: any) => item.device_id);
    if (this.checkedValues.length > 0)
      this.isButtonDisabled = false;
    else
      this.isButtonDisabled = true;

  }

  manualAllocation() {
    if (this.portalId == null && this.portalId == undefined) {
      this.toastrService.warning('Please enter any Portal.')
      return
    }
    let body = {
      portalId: this.portalId,
      device_ids: this.checkedValues
    }
    console.log(body);

    this.ngxLoader.start();
    this.api.postRequest('manual-allocation', body).subscribe(item => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Device have been manually allocate",
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['manual-portal-allocation']);
      this.ngxLoader.stop();
    })


  }

  clearFile() {
    // Clear the selected file by resetting the value of the hidden input field
    this.hiddenInput.nativeElement.value = '';
    var element = document.getElementById("fakeFileInput") as HTMLInputElement;
    element.value = '';
    this.dataSource = new MatTableDataSource();
    this.dataSource2 = new MatTableDataSource();
    this.existingDevices = 0
  }

}

