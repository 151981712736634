<app-header></app-header>
<section class="header">
    <div class="container">
        <div class="header-text">
            <a routerLink="/home">
                <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
            </a>
            <h2>Customer Allocations</h2>
            <a href="#">
                <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
            </a>
        </div>
    </div>
</section>
<section class="table">
    <div class="container">
        <div class="text-center">
            <p>Allocations History</p>
        </div>
        <span class="table-top">Deallocate Devices</span>
        <mat-form-field class="example-full-width">
            <input type="text" placeholder="Search portal" (click)="onMouseEnter()" aria-label="Portal" matInput
                [formControl]="allPortalNames" [matAutocomplete]="auto" (ngModelChange)=myPortalNames($event)>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id_name">
                    {{option.id_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="inner-table table-responsive" *ngIf="dataSource.data.length > 0">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                class="mat-elevation-z8">
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ID">
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index;">
                        <input type="checkbox" style="appearance: auto !important; width: 20px; height: 20px;"
                            name="device_id" value="{{ element._id }}" [(ngModel)]="element.checked">
                    </td>
                </ng-container>

                <ng-container matColumnDef="device_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by device_id">
                        IMEI
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.device_id}} </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
                        Time
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <div class="erase-btn">
                <a class="erase-selected" (click)="eraseCheckedValues()" title="Erase Selected Device">
                    <img src="../../../assets/icons/rollback.png" />
                </a>
                <!-- <a class="erase-all" title="Erase All Devices" (click)="eraseAllValues()">
                <img src="../../../assets/icons/Erase-All.svg" />
            </a> -->
            </div>
        </div>
    </div>
</section>