import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { UserlistComponent } from './components/userlist/userlist.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RealTimeComponent } from './components/real-time/real-time.component';
import { AutoAllocationComponent } from './components/auto-allocation/auto-allocation.component';
import { EraseDeviceComponent } from './components/erase-device/erase-device.component';
import { AllocatedDeviceComponent } from './components/allocated-device/allocated-device.component';
import { DeallocateDeviceComponent } from './components/deallocate-device/deallocate-device.component';
import { ManualPortalAllocationComponent } from './components/manual-portal-allocation/manual-portal-allocation.component';
import { PortalTransferComponent } from './components/portal-transfer/portal-transfer.component';
import { ManualPortalAllocationListComponent } from './components/manual-portal-allocation-list/manual-portal-allocation-list.component';
import { PortalTransferListComponent } from './components/portal-transfer-list/portal-transfer-list.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegistrationComponent },
  { path: 'home', component: HomeComponent },
  { path: 'userlist', component: UserlistComponent },
  { path: 'authentication', component: AuthenticationComponent },
  { path: 'test', component: AuthenticationComponent },
  { path: 'forgot-password', component: ForgotComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'realTime', component: RealTimeComponent },
  { path: 'erase-device', component: EraseDeviceComponent },
  { path: 'auto-allocate', component: AutoAllocationComponent },
  { path: 'allocate-device', component: AllocatedDeviceComponent },
  { path: 'deallocate-device', component: DeallocateDeviceComponent },
  { path: 'manual-portal-allocation', component: ManualPortalAllocationComponent },
  { path: 'manual-portal-allocation-list', component: ManualPortalAllocationListComponent },
  { path: 'portal-transfer', component: PortalTransferComponent },
  { path: 'portal-transfer-list', component: PortalTransferListComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
