import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-real-time',
  templateUrl: './real-time.component.html',
  styleUrls: ['./real-time.component.scss']
})
export class RealTimeComponent implements OnInit {

  result: any;
  portalList: any[] = [];
  poratalId: string = '';
  slugUrl: string = '';

  constructor(private api: ApiService, private router: Router, private toastrService: ToastrService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.api.getRequest('target-portal').subscribe(item => {
      this.ngxLoader.stop();
      this.portalList = item;
      if (this.portalList.length >= 1) {
        this.router.navigate(['auto-allocate']);
      } else {
        this.getAllPortalList();
      }
    },
      () => {
        this.ngxLoader.stop();
      });
  }


  getAllPortalList() {
    this.ngxLoader.start();
    this.api.getRequest('real-time').subscribe(item => {
      this.ngxLoader.stop();
      this.portalList = item;
      this.portalList.forEach(item => {
        item.isSelected = false;
      });
    },
      () => {
        this.ngxLoader.stop();
      });
  }

  selectPortal(id: string, item: any) {
    this.portalList.forEach(i => i.isSelected = false);

    item.isSelected = true;
    if (item.slug == undefined) {
      Swal.fire("Oops! URL missing of this portal.", "", "info");
      this.poratalId = ''
      this.slugUrl = ''
      return
    }
    Swal.fire("You have chosen " + item.slug, "", "success");
    this.poratalId = id;
    this.slugUrl = item.slug;
  }

  updatePortal() {
    let body = {
      status: true
    }
    if (!this.poratalId) {
      Swal.fire("Please select any portal ", "", "info");
      // this.toastrService.warning('Please select any portal')
      return
    }
    this.ngxLoader.start();
    this.api.postRequest('target-portal/' + this.poratalId, body).subscribe(item => {
      this.ngxLoader.stop();
      this.router.navigate(['home']);
    },
      () => {
        this.ngxLoader.stop();
      });
  }
}
