import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-erase-device',
  templateUrl: './erase-device.component.html',
  styleUrls: ['./erase-device.component.scss']
})
export class EraseDeviceComponent implements OnInit {
  originalTimeZone = "UTC";
  targetTimeZone = "Australia/Brisbane";
  selectAll: boolean = false;
  resultData: any[] = [];
  displayedColumns: string[] = ['ID', 'device_id', 'date'];
  dataSource = new MatTableDataSource();

  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private datePipe: DatePipe, private ngxLoader: NgxUiLoaderService) { }
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    this.getUnallocatedDevices();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getUnallocatedDevices() {
    this.ngxLoader.start();
    this.api.getRequest('fp/unallocated-devices').subscribe(item => {

      const responce = item;
      this.ngxLoader.stop();
      const result = responce.map((items: any, index: any) => {
        return { ...items, ID: (index + 1).toString() };
      });
      const resultArray = result.map((itemss: any) => {
        return { ...itemss, date: this.formatDate(itemss.date) };
      });
      this.resultData = resultArray.sort((a: any, b: any) => {

        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();

        return dateB - dateA;
      });
      this.dataSource = new MatTableDataSource(this.resultData);
      this.dataSource.sort = this.sort;
    },
      () => {
        this.ngxLoader.stop();
      });

  }

  private formatDate(dateString: Date) {
    // const isoFormattedDateString = dateString.replace(/_/g, 'T').replace(/:/g, '-');


    const originalDate = moment(dateString, 'YYYY-MM-DDTHH-mm-ss');
    const brisbaneTime = originalDate.tz('Australia/Brisbane');
    const formattedBrisbaneTime = brisbaneTime.format('YYYY-MM-DD HH:mm:ss');

    // console.log('Original Date:', dateString);
    // console.log('Brisbane Time:', formattedBrisbaneTime);
    return formattedBrisbaneTime
  }


  eraseCheckedValues() {
    const checkedItems = this.resultData.filter((item: any) => item.checked);
    const checkedValues = checkedItems.map((item: any) => item.device_id);
    if (checkedValues.length <= 0) {
      this.toastrService.warning('Please select any device.')
      return
    }

    let body: any = {
      arrayOfIds: checkedValues
    }
    Swal.fire({
      title: "Are you sure you want to remove devices?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Erase",
      denyButtonText: `Cancel`
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.ngxLoader.start();
        this.api.postRequest('fp/unallocated-devices', body).subscribe(item => {
          this.ngxLoader.stop();
          this.getUnallocatedDevices();
          this.toastrService.success(item.message)
        },
          () => {
            this.ngxLoader.stop();
          });
      }
    });

  }
  eraseAllValues() {

    const checkedValue = this.resultData.map(entry => entry.device_id);

    let body: any = {
      arrayOfIds: checkedValue
    }

    Swal.fire({
      title: "Are you sure you want to remove all devices?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Erase all",
      denyButtonText: `Cancel`
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.ngxLoader.start();
        this.api.postRequest('fp/unallocated-devices', body).subscribe(item => {
          this.ngxLoader.stop();
          this.getUnallocatedDevices();
          this.toastrService.success(item.message)
        },
          () => {
            this.ngxLoader.stop();
          });
      }
    });

  }

}
