<section class="header">
    <div class="main-container">
        <div class="inner-row">
            <div class="inner-flx-bx">
                <div class="logo">
                    <a routerLink="/home"><img src="../../../assets/images/tracNetLogo.png" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</section>