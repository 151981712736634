<mat-form-field class="example-full-width">
    <input type="text" placeholder="Select a Portal" aria-label="Portal" matInput [formControl]="allPortalNames"
        [matAutocomplete]="auto" (ngModelChange)=myPortalNames($event) (click)="onMouseEnter()">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id_name">
            {{option.id_name}}
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix (click)="onMouseEnter()">
        <mat-icon>expand_more</mat-icon></button>
</mat-form-field>