import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
  result: any;
  emailVerified: boolean = false;
  showVerificationMessage: boolean = false; // Add the emailVerified property

  constructor(private builder: FormBuilder, private router: Router, private api: ApiService, private toastr: ToastrService, private ngxLoader: NgxUiLoaderService) { sessionStorage.clear(); }

  registrationForm = this.builder.group({
    username: this.builder.control('', Validators.required),
    name: this.builder.control('', Validators.required),
    password: this.builder.control('', Validators.required),
    email: this.builder.control('', [Validators.required, Validators.email])

  });

  onSubmit() {

    let body: any = {
      username: this.registrationForm.value.username,
      password: this.registrationForm.value.password,
      email: this.registrationForm.value.email,
      name: this.registrationForm.value.name
    }
    if (this.registrationForm.valid) {
      this.ngxLoader.start();

      this.api.postRequest('auth/signup', body).subscribe(item => {

        this.result = item;
        this.ngxLoader.stop();
        if (this.result) {
          this.toastr.success('Registration successfully.')
        }
      },
        () => {
          this.toastr.warning('Please enter valid data.')
          this.ngxLoader.stop();
        });

    }
  }

}
