<app-header></app-header>
<section class="header">
    <div class="container">
        <div class="header-text inner-module-custom-class">
            <a routerLink="/home">
                <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
            </a>
            <h2>Manual Portal Allocation</h2>
        </div>
    </div>
</section>
<section class="table">
    <div class="container">
        <div class="modal-buttons">
            <form action="">

                <app-child-select-portal (valueChange)="onChanges($event)"></app-child-select-portal>

                <mat-form-field class="example-chip-list">
                    <mat-chip-grid #chipList aria-label="values selection">
                        <mat-chip-row *ngFor="let values of allImeis" [removable]="removable"
                            (removed)="remove(values)">
                            {{values}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip-row>
                        <input placeholder="Select TracNet IMEI" #imeiInputs
                            #autocompleteTrigger="matAutocompleteTrigger" [formControl]="imeiCtrl"
                            [matAutocomplete]="auto2" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
                            (click)="openAuto(autocompleteTrigger)">
                    </mat-chip-grid>
                    <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="selected($event)"
                        autoActiveFirstOption>
                        <mat-option *ngFor="let values of filteredImeis | async" [value]="values.device_id">
                            <mat-checkbox color="primary" style="padding: 0 12px;"
                                [checked]="allImeis.indexOf(values.device_id) >= 0"></mat-checkbox>
                            {{values.device_id}}
                        </mat-option>
                        <mat-option *ngIf="allIMEIlData.length == 0" value=" " disabled>
                            Record not found.
                        </mat-option>
                    </mat-autocomplete>
                    <button mat-icon-button matSuffix (click)="openAuto(autocompleteTrigger)">
                        <mat-icon>expand_more</mat-icon></button>
                </mat-form-field>
                <div class="example-button-row">
                    <button mat-stroked-button color="success" (click)="manualAllocation()"
                        [disabled]="isButtonDisabled">ALLOCATE</button>

                </div>
                <ul class="btn-list">
                    <li><a routerLink="/manual-portal-allocation-list">Load a List</a></li>
                    <li><a routerLink="/home">Cancel</a></li>
                </ul>
            </form>
        </div>
    </div>
</section>