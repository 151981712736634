<style>
    body {
      background-color: #4472c4;
    }
  </style>
  
  <div class="login-main-bg-color">
    <div class="main-container">
      <div class="inner-row">
        <div class="login_screen">
          <img src="../../../assets/images/tracNetLogo.png" alt="">
          <h2>Forgot Password</h2>
          <form class="example-form" [formGroup]='resetPasswordForm' autocomplete="off" (ngSubmit)="onSubmit()">
            <mat-form-field class="example-full-width">
              <mat-label>New password</mat-label>
              <input matInput  type="password" formControlName="newPassword" required autocomplete="off">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Confirm new password</mat-label>
                <input matInput  type="password" formControlName="confirmPassword" required autocomplete="off">
            </mat-form-field>
            <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" class="form-text text-danger" >
              <div *ngIf="confirmPassword.errors?.['required']">
                Field is required*
              </div>
              <div *ngIf="confirmPassword.errors?.['confirmedValidator']">
                Passwords did not match
              </div>
            </div>
            <button [disabled]="resetPasswordForm.invalid" type="submit" class="save_btn"> Change Password</button>
          </form>
        </div>
  
      </div>
    </div>
  </div>