import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';

export class allPortals {
  constructor(public _id: string, public id_name: string) { }
}

@Component({
  selector: 'app-deallocate-device',
  templateUrl: './deallocate-device.component.html',
  styleUrls: ['./deallocate-device.component.scss']
})
export class DeallocateDeviceComponent {
  resultData: any[] = [];
  displayedColumns: string[] = ['ID', 'device_id', 'date'];
  dataSource = new MatTableDataSource();
  allPortalNames = new FormControl();
  // myPortalNames: any
  filteredOptions: Observable<any[]> | undefined;
  allPoratlData: any[] = [];
  portalId!: '';



  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private ngxLoader: NgxUiLoaderService, private router: Router) { }

  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allPoratlData.filter(option => option.id_name.toLowerCase().includes(filterValue));
  }

  ngOnInit() {
    this.getAllPoratlData()

    this.filteredOptions = this.allPortalNames.valueChanges.pipe(startWith(''), map((value: string) => this._filter(value)));
  }
  getUnallocatedDevices() {
    this.ngxLoader.start();
    this.api.getRequest('auto-allocation/' + this.portalId).subscribe(item => {
      this.resultData = item;
      this.ngxLoader.stop();
      if (this.resultData.length == 0) {
        this.toastrService.warning('Device not found of selected portal.')
      }
      this.resultData = this.resultData.map((items: any, index: any) => {
        return { ...items, ID: (index + 1).toString() };
      });
      this.resultData = this.resultData.map((itemss: any) => {
        return { ...itemss, date: this.formatDate(itemss.updated_at) };
      });
      this.resultData = this.resultData.map((itemss: any) => {
        return { ...itemss, device_id: itemss.product[0].id_serial };
      });
      this.dataSource = new MatTableDataSource(this.resultData);
      this.dataSource.sort = this.sort;
    },
      () => {
        this.ngxLoader.stop();
      });

  }
  getAllPoratlData() {
    this.api.getRequest('real-time').subscribe(item => {
      this.allPoratlData = item;
    })

  }
  private formatDate(dateString: string): string {
    const ustTimeZone = 'UTC';
    const brisbaneTimeZone = 'Australia/Brisbane';

    const istDate = new Date(dateString);

    // Convert from IST to UST (UTC)
    const ustDate = istDate.toLocaleString('en-US', {
      timeZone: ustTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    // Convert from UST (UTC) to Brisbane
    const brisbaneDate = new Intl.DateTimeFormat('en-AU', {
      timeZone: brisbaneTimeZone,
      hour: '2-digit', minute: '2-digit',
      hour12: true,
    }).format(new Date(ustDate));

    return brisbaneDate || '';
  }
  myPortalNames(event: any) {
    const filteredData = this.allPoratlData.filter(item => item.id_name === event);
    if (filteredData.length > 0) {
      this.portalId = filteredData[0]._id
      this.getUnallocatedDevices();
      // console.log(event, filteredData);
    } else {
      this.portalId = '';
      this.dataSource = new MatTableDataSource();
    }
  }

  eraseCheckedValues() {
    if (sessionStorage.getItem('usertype') != 'Admin') {
      Swal.fire("Oops! You are not authorized to perform this action.", "", "info");
      return
    }

    if (this.portalId == '' || this.portalId == undefined) {
      this.toastrService.warning("Please select any portal.", this.portalId)
      return
    }
    const checkedItems = this.resultData.filter((item: any) => item.checked);
    const checkedValues = checkedItems.map(item => ({ device_id: item.product[0].id_serial }));
    console.log(checkedItems, checkedValues);
    if (checkedValues.length <= 0) {
      this.toastrService.warning('Please select any device.')
      return
    }



    let body: any = {
      portalRef: this.portalId,
      device_data: checkedValues
    }
    Swal.fire({
      title: "Are you sure you want to remove devices?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Erase",
      denyButtonText: `Cancel`
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.ngxLoader.start();
        this.api.postRequest('device-install/deallocated-product', body).subscribe(item => {
          this.ngxLoader.stop();
          this.toastrService.success(item.message)
          this.router.navigate(['home'])
        },
          () => {
            this.ngxLoader.stop();
          });
      }
    });

  }

  onMouseEnter() {
    this.allPortalNames.setValue('');
  }
}
