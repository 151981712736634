<app-header></app-header>
<section class="header">
    <div class="container">
        <div class="header-text inner-module-custom-class">
            <a routerLink="/home">
                <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
            </a>
            <h2>Manual Portal Allocation</h2>
        </div>
    </div>
</section>
<!-- Modal -->
<section class="table">
    <div class="container">
        <div class="text-center">
            <p>Load a List of Units</p>
        </div>
        <div class="modal-buttons">
            <form action="">
                <app-child-select-portal (valueChange)="onChanges($event)"></app-child-select-portal>
                <fa-icon class="text-right" [icon]="faInfoCircle" mat-raised-button #tooltip="matTooltip"
                    [matTooltip]="tooltipText" matTooltipPosition="right" matTooltipClass="multiline-tooltip" (click)="tooltip.toggle()"></fa-icon>
                <mat-form-field>
                    <input matInput readonly type="text" id="fakeFileInput" (click)="hiddenInput.click(); clearFile()"
                        placeholder="Select Text File">
                    <input hidden type="file" accept=".txt" #hiddenInput (change)="GetFileOnLoad($event)">
                    <button mat-icon-button matSuffix (click)="clearFile()" *ngIf="dataSource.data.length > 0">
                        <mat-icon><span class="material-symbols-outlined">
                                close
                            </span></mat-icon></button>
                </mat-form-field>

                <section class="table">
                    <div class="container">
                        <span class="table-top">Selected Units </span>
                        <div class="inner-table table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort
                                (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

                                <!-- Position Column -->
                                <ng-container matColumnDef="ID">
                                    <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by ID">
                                    </th>
                                    <td mat-cell *matCellDef="let element; let i = index;">
                                        <input type="checkbox"
                                            style="appearance: auto !important; width: 20px; height: 20px;"
                                            name="device_id" value="{{ element.device_id }}"
                                            [(ngModel)]="element.checked" (change)="onCheckboxChange(element)">
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="device_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        sortActionDescription="Sort by device_id">
                                        IMEI
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.device_id}} </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        sortActionDescription="Sort by date">
                                        Time
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                    
                </section>
                <div class="example-button-row">
                    <a href="javascript:void(0)" (click)="toggleVisibility()"
                        *ngIf="existingDevices > 1">{{existingDevices}} IMEI’s already allocated</a>
                    <a href="javascript:void(0)" (click)="toggleVisibility()"
                        *ngIf="existingDevices === 1">{{existingDevices}} IMEI already allocated</a>
                </div>
                <div class="example-button-row">
                    <button color="success" (click)="manualAllocation()"
                        [disabled]="isButtonDisabled">ALLOCATE {{(dataSource.data.length >0) ?dataSource.data.length:
                            ''}} UNIT<span *ngIf="dataSource.data.length > 1">s</span>  </button>
                </div>
                <ul class="btn-list">
                    <li><a routerLink="/manual-portal-allocation">Cancel</a></li>
                </ul>
            </form>
        </div>
    </div>
</section>

<!-- Modal -->
<div class="modal" id="myModal" [hidden]="!isShown">
    <div class="modal-content">
        <h1>
            <p>IMEI that are already allocated to a portal</p>
        </h1>
        <section class="table">
            <div class="container">
                <span class="table-top">Selected Units </span>
                <div class="inner-table table-responsive">
                    <table mat-table [dataSource]="dataSource2" matSort (matSortChange)="announceSortChange2($event)"
                        class="mat-elevation-z8">

                        <!-- Position Column -->
                        <ng-container matColumnDef="Portal">
                            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Portal">
                                Portal Name
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.portal}} </td>
                        </ng-container>

                        <ng-container matColumnDef="device_id2">
                            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by device_id2">
                                IMEI
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.device_id2}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="date2">
                            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by date2">
                                Time
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.date2}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
                    </table>
                </div>
                <div class="modal-buttons">
                    <a href="javascript:void(0)" (click)="closeModal()">
                        <div class="cancel-btn">
                            <div class="btn-text">
                                <p>CANCEL</p>
                                <div class="btn-icon">
                                    <img src="../../../assets/icons/close.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    </div>
</div>