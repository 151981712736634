<app-header></app-header>
<section class="header">
  <div class="container">
    <div class="header-text">
      <a routerLink="/home">
        <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
      </a>
      <h2>Customer User List</h2>
      <a href="#">
        <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
      </a>
    </div>
  </div>
</section>
<section class="table-block">
  <div class="main-container">
    <!-- <a class="back_to" routerLink="/home">Back</a> -->
    <div class="inner-row">
      <div class="inner-table table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <!-- <th>Username</th> -->
              <!-- <th>User Type</th> -->
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of getPaginatedUsersData()" class="active-row">
              <td>{{ item.name }}</td>
              <!-- <td>{{ item.username }}</td> -->
              <!-- <td>{{ item.usertype??'User' }}</td> -->
              <td>{{ item.email }}</td>
              <td>
                <button [class]="(item.status === false) ? 'Inactive' : 'Active'" (click)="changestatus(item._id)">{{
                  (item.status === false) ? 'Inactive' : 'Active' }}</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination">
          <button [disabled]="currentPage === 1" (click)="previousPage()">Previous</button>
          <span>Page {{ currentPage }} of {{ totalPages() }}</span>
          <button [disabled]="currentPage === totalPages()" (click)="nextPage()">Next</button>
        </div>
      </div>
    </div>
  </div>
</section>