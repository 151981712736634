import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-portal-transfer-list',
  templateUrl: './portal-transfer-list.component.html',
  styleUrls: ['./portal-transfer-list.component.scss']
})
export class PortalTransferListComponent {
  portalId: any;
  @ViewChild('hiddenInput') hiddenInput: any;
  @ViewChild(MatSort) sort!: MatSort;
  resultData: any[] = [];
  displayedColumns: string[] = ['ID', 'device_id', 'date'];
  dataSource = new MatTableDataSource();
  isButtonDisabled: boolean = true; // Initially, button is enabled
  checkedValues!: any[];
  faInfoCircle = faInfoCircle;
  tooltipText = 'File content must be a text file where each line contains a TracNet IMEI';
  unitNo = 0;

  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private ngxLoader: NgxUiLoaderService, private router: Router) { }

  ngOnInit() {

  }

  onChanges(event: any) {
    this.portalId = event

  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  private formatDate(dateString: Date) {
    // const isoFormattedDateString = dateString.replace(/_/g, 'T').replace(/:/g, '-');


    const originalDate = moment(dateString, 'YYYY-MM-DDTHH-mm-ss');
    const brisbaneTime = originalDate.tz('Australia/Brisbane');
    const formattedBrisbaneTime = brisbaneTime.format('YYYY-MM-DD HH:mm:ss');

    // console.log('Original Date:', dateString);
    // console.log('Brisbane Time:', formattedBrisbaneTime);
    return formattedBrisbaneTime
  }

  public GetFileOnLoad(event: any) {
    var file = event.target.files[0];
    if (file) {
      this.readFile(file);
    }
    var element = document.getElementById("fakeFileInput") as HTMLInputElement | null;
    if (element != null) {
      element.value = file?.name;
    }
  }
  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        const fileContent: string = reader.result as string;
        const dataArray = fileContent.split('\n').map((line: string) => {
          const trimmedLine = line.replace(/\s/g, '');
          if (trimmedLine.length !== 15) {
            this.clearFile();
            Swal.fire({
              position: "center",
              icon: "error",
              title: "IMEI's Data Is Not In Correct Format.",
              showConfirmButton: true,
              timer: 3000
            });
            throw new Error("Each string should have exactly 15 characters.");
          }
          return trimmedLine;
        });

        this.ngxLoader.start();
        let payload = {
          portalId: this.portalId,
          device_ids: dataArray
        };

        this.api.postRequest('manual-allocation/get-existDevices-by-file', payload).subscribe(
          item => {
            const responce = item;
            this.unitNo = responce.length;
            this.ngxLoader.stop();
            const result = responce.map((items: any, index: any) => {
              return { ...items, ID: (index + 1).toString() };
            });
            const resultArray = result.map((itemss: any) => {
              return { ...itemss, date: this.formatDate(itemss.date) };
            });
            this.resultData = resultArray.sort((a: any, b: any) => {

              const dateA = new Date(a.date).getTime();
              const dateB = new Date(b.date).getTime();

              return dateB - dateA;
            });
            this.dataSource = new MatTableDataSource(this.resultData);
            this.dataSource.sort = this.sort;
          },
          error => {
            this.ngxLoader.stop();
            console.error("Error occurred while processing the API request:", error);
          }
        );
      } catch (error) {
        console.error("Error occurred while processing the file:", error);
      }
    };
    reader.onerror = (event) => {
      console.error("File reading error:");
    };

    reader.readAsText(file);
  }

  clearFile() {
    // Clear the selected file by resetting the value of the hidden input field
    this.hiddenInput.nativeElement.value = '';
    var element = document.getElementById("fakeFileInput") as HTMLInputElement;
    element.value = '';
    this.dataSource = new MatTableDataSource();
  }

  onCheckboxChange(element: any) {
    const checkedItems = this.resultData.filter((item: any) => item.checked);
    this.checkedValues = checkedItems.map((item: any) => item.device_id);
    if (this.checkedValues.length > 0)
      this.isButtonDisabled = false;
    else
      this.isButtonDisabled = true;

  }

  transferPortal() {
    if (this.portalId == null && this.portalId == undefined) {
      this.toastrService.warning('Please enter any Portal.')
      return
    }
    let body = {
      portalId: this.portalId,
      device_ids: this.checkedValues
    }

    this.ngxLoader.start();
    this.api.postRequest('manual-allocation/portal-transfer', body).subscribe(item => {

      console.log(body, item);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Device have been transfered",
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['portal-transfer']);
      this.ngxLoader.stop();
    })


  }
}
