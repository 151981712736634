<app-header></app-header>
<section class="header">
  <div class="container">
    <div class="header-text inner-module-custom-class">
      <a routerLink="/home">
        <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
      </a>
      <h2>Customer Allocations</h2>
      <!-- <a href="#">
        <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
      </a> -->
    </div>
  </div>
</section>

<section class="table real-table-section">
  <div class="container">
    <div class="text-center">
      <p>Real-Time</p>
    </div>
    <span class="table-top"> Target Portal </span>
    <div class="inner-table table-responsive">
      <table class="real-time-table">
        <thead>
          <tr>
            <th>Customer</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of portalList" (click)="selectPortal(item._id, item)" [class.selected]="item.isSelected">
            <td>{{ item.id_name }}</td>
            <td><a href="javascript:void(0)">{{ item.slug }}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <p *ngIf="slugUrl !== ''">You have chosen {{ slugUrl }}</p>
    <div class="bottom-btn">
      <a href="javascript:void(0)" (click)="updatePortal()">
        <div class="start-btn">
          <div class="btn-icon">
            <img src="../../../assets/icons/Tick.svg" alt="Invalid img" />
          </div>
          <div class="btn-text">
            <p>START AUTOMATICALLY ALLOCATING NEW DEVICES</p>
          </div>
        </div>
      </a>

      <a routerLink="/home" class="cancel-btn">Cancel</a>
    </div>
  </div>
</section>