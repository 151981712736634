import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, map, startWith } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChildSelectPortalComponent } from '../child-select-portal/child-select-portal.component';

@Component({
  selector: 'app-portal-transfer',
  templateUrl: './portal-transfer.component.html',
  styleUrls: ['./portal-transfer.component.scss']
})
export class PortalTransferComponent implements OnInit {
  @Input('matChipInputFor') chipGrid: MatChipGrid | undefined
  @ViewChild('imeiInputs') imeiInputs!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;
  @ViewChild('autocompleteTrigger') matACTrigger!: MatAutocompleteTrigger;
  autocompleteTrigger!: MatAutocompleteTrigger;
  imeiCtrl = new FormControl();
  filteredImeis: Observable<any[]>;
  portalId: any;
  resultData: any
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allImeis: any[] = [];
  allFruits: any[] = [];
  opened = false;
  isButtonDisabled: boolean = true; // Initially, button is enabled
  @ViewChild(ChildSelectPortalComponent) ChildSelectPortalComponent!: ChildSelectPortalComponent;

  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private ngxLoader: NgxUiLoaderService, private router: Router) {

    this.filteredImeis = this.imeiCtrl.valueChanges.pipe(startWith(null), map((value: any) => this._filter(value)));
  }


  ngOnInit() {
    this.getUnallocatedDevices()

  }


  onChanges(event: any) {
    this.portalId = event

  }



  getUnallocatedDevices() {
    this.ngxLoader.start();
    this.api.getRequest('manual-allocation/get-portal-devices/').subscribe(item => {
      this.allFruits = item;
      console.log(this.allFruits.length);

      this.ngxLoader.stop();
    },
      () => {
        this.ngxLoader.stop();
      });

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our values
    if ((value || '').trim()) {
      this.allImeis.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.imeiCtrl.setValue(null);
  }

  remove(values: string): void {
    const index = this.allImeis.indexOf(values);

    if (index >= 0) {
      this.allImeis.splice(index, 1);
    }
    if (this.allImeis.length < 1)
      this.isButtonDisabled = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;
    if (this.allImeis.includes(newValue)) {
      this.allImeis = [...this.allImeis.filter(values => values !== newValue)];
    } else {
      this.allImeis.push(event.option.viewValue);
    }
    this.imeiInputs.nativeElement.value = '';
    this.imeiCtrl.setValue(null);

    // keep the autocomplete opened after each item is picked.
    // requestAnimationFrame(() => {
    this.openAuto(this.matACTrigger);
    // })

    if (this.allImeis.length > 0)
      this.isButtonDisabled = false;
    else
      this.isButtonDisabled = true;

  }

  private _filter(value: string): string[] {
    const filterValue = value;
    return this.allFruits.filter(values => values.id_serial.toLowerCase().includes(filterValue));
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.imeiInputs.nativeElement.focus();
    this.imeiCtrl.setValue('')
  }
  transferPortal() {
    let body = {
      portalId: this.portalId,
      device_ids: this.allImeis.map(item => item.split(" ")[0])
    }

    this.ngxLoader.start();
    this.api.postRequest('manual-allocation/portal-transfer', body).subscribe(item => {
      this.ChildSelectPortalComponent.onMouseEnter();
      this.allImeis = []
      this.isButtonDisabled = true;
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Device have been transfered",
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['portal-transfer']);
      this.ngxLoader.stop();
    })


  }
}
