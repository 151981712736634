import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-auto-allocation',
  templateUrl: './auto-allocation.component.html',
  styleUrls: ['./auto-allocation.component.scss']
})
export class AutoAllocationComponent implements OnInit {

  totalUnitCount: any;
  getLoggedInUserName: any
  getAutoAllocatePortal: any
  isShown: boolean = false;
  poratalId: any
  formattedDate!: string;
  startedAt!: any
  minutesDiff: any;
  intervalId: any;

  constructor(private api: ApiService, private router: Router, private datePipe: DatePipe, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.ngxLoader.start();
    this.api.getRequest('target-portal').subscribe(item => {
      this.ngxLoader.stop();
      this.getLoggedInUserName = item[0].users[0].name;
      this.getAutoAllocatePortal = item[0].id_name;
      this.poratalId = item[0]._id;
      this.startedAt = item[0].startedAt;
      sessionStorage.setItem('poratalId', item[0]._id);

      if (parseInt(item[0].startedAt)) {
        const timeZone = 'Australia/Brisbane';
        const inputDate = new Date(this.startedAt);
        const formatter = new Intl.DateTimeFormat('en-AU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
          timeZone,
        });

        const formattedDate = formatter.format(inputDate);

        const currentDate = new Date();

        const timeDiff = Math.abs(currentDate.getTime() - inputDate.getTime());

        var cd = 24 * 60 * 60 * 1000;
        if (timeDiff < 1000) {
          // milliseconds
          this.minutesDiff = timeDiff + " ms";
        } else if (timeDiff < 60000) {
          // seconds
          this.minutesDiff = Math.floor(timeDiff / 1000) + " second" + (timeDiff >= 2000 ? "s" : "");
        } else if (timeDiff < 3600000) {
          // minutes
          this.minutesDiff = Math.floor(timeDiff / 60000) + " minute" + (timeDiff >= 120000 ? "s" : "");
        } else if (timeDiff < 86400000) {
          // hours
          this.minutesDiff = Math.floor(timeDiff / 3600000) + " hour" + (timeDiff >= 7200000 ? "s" : "");
        } else if (timeDiff < 604800000) {
          // days
          this.minutesDiff = Math.floor(timeDiff / 86400000) + " day" + (timeDiff >= 86400000 ? "s" : "");
        } else if (timeDiff < 31536000000) {
          // weeks
          this.minutesDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)) + " week" + (timeDiff >= 1209600000 ? "s" : "");
        } else {
          // years
          this.minutesDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365)) + " year" + (timeDiff >= 31536000000 * 2 ? "s" : "");
        }

        this.formattedDate = formattedDate;

      }

      this.fetchUnit(this.poratalId);

    },
      () => {
        this.ngxLoader.stop();
      });
    this.intervalId = setInterval(() => {
      this.fetchUnit(this.poratalId);
    }, 5000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  fetchUnit(poratalId: any) {
    this.api.getRequest('auto-allocation/' + poratalId).subscribe(item => {
      this.totalUnitCount = item.length;
    });
  }


  toggleVisibility(): void {
    this.isShown = !this.isShown;
  }

  closeModal(): void {
    this.isShown = !this.isShown;
  }

  confirmAction(): void {
    let body = {
      status: false
    }
    this.ngxLoader.start();
    this.api.postRequest('target-portal/' + this.poratalId, body).subscribe(item => {
      this.ngxLoader.stop();
      this.router.navigate(['home']);
    },
      () => {
        this.ngxLoader.stop();
      });
    this.isShown = !this.isShown;
  }

}
