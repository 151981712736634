<app-header></app-header>
<!-- header -->
<section class="table-block home-page">
  <div class="main-container">
    <div class="inner-row">
      <section class="header">
        <div class="container">
          <div class="header-text">
            <!-- <a routerLink="/home">
                      <img src="../../../assets/icons/back-01.svg" alt="Invalid img"
                      />
                    </a> -->
            <h2>Customer Allocations</h2>
            <a href="#">
              <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
            </a>
          </div>
        </div>
      </section>

      <section class="btn-group">
        <div class="container">
          <div class="btn-wrp">
            <div class="grp-1">
              <a routerLink="/realTime">
                <div class="active">
                  Real-Time
                </div>
              </a>

              <a routerLink="/manual-portal-allocation">
                <div class="active">
                  Manual Portal Allocation
                </div>
              </a>
              <a routerLink="/portal-transfer">
                <div class="active">
                  Portal Transfer
                </div>
              </a>

              <a href="javascript:void(0)">
                <div class="active disabled">
                  Historical
                </div>
              </a>
              <!-- <a routerLink="/deallocate-device">
                <div class="active">
                  Allocations History
                </div>
              </a> -->
              <a href="javascript:void(0)">
                <div class="active disabled">
                  Allocations History
                </div>
              </a>
              <a routerLink="/userlist">
                <div class="active">
                  Users
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="btn-group btn-2">
        <div class="container">
          <div class="btn-wrp">
            <div class="grp-2">
              <a routerLink="/erase-device">
                <div class="active">
                  Erase Device History
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<!-- Modal -->