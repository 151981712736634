import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-allocated-device',
  templateUrl: './allocated-device.component.html',
  styleUrls: ['./allocated-device.component.scss']
})

export class AllocatedDeviceComponent implements OnInit {
  resultData: any;
  displayedColumns: string[] = ['ID', 'device_id', 'date'];
  dataSource = new MatTableDataSource();



  constructor(private api: ApiService, private toastrService: ToastrService, private _liveAnnouncer: LiveAnnouncer, private ngxLoader: NgxUiLoaderService) { }

  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }


  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit() {

    this.getUnallocatedDevices();
  }
  getUnallocatedDevices() {
    this.ngxLoader.start();
    var portalId = sessionStorage.getItem('poratalId');
    this.api.getRequest('auto-allocation/' + portalId).subscribe(item => {
      this.resultData = item;
      this.ngxLoader.stop();
      this.resultData = this.resultData.map((items: any, index: any) => {
        return { ...items, ID: (index + 1).toString() };
      });
      this.resultData = this.resultData.map((itemss: any) => {
        return { ...itemss, date: this.formatDate(itemss.updated_at) };
      });
      this.resultData = this.resultData.map((itemss: any) => {
        return { ...itemss, device_id: itemss.product[0].id_serial };
      });
      this.dataSource = new MatTableDataSource(this.resultData);
      this.dataSource.sort = this.sort;
    },
      () => {
        this.ngxLoader.stop();
      });

  }
  private formatDate(dateString: string): string {
    const ustTimeZone = 'UTC';
    const brisbaneTimeZone = 'Australia/Brisbane';

    const istDate = new Date(dateString);

    // Convert from IST to UST (UTC)
    const ustDate = istDate.toLocaleString('en-US', {
      timeZone: ustTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    // Convert from UST (UTC) to Brisbane
    const brisbaneDate = new Intl.DateTimeFormat('en-AU', {
      timeZone: brisbaneTimeZone,
      hour: '2-digit', minute: '2-digit',
      hour12: true,
    }).format(new Date(ustDate));

    return brisbaneDate || '';
  }

}
