<app-header></app-header>
<section class="header">
  <div class="container">
    <div class="header-text">
      <a routerLink="/home">
        <img src="../../../assets/icons/back-01.svg" alt="Invalid img" />
      </a>
      <h2>Customer Allocations</h2>
      <a href="#">
        <img src="../../../assets/icons/Logout-red.svg" alt="Invalid img" />
      </a>
    </div>
  </div>
</section>
<section class="summary">
  <div class="container">
    <div class="on-off">
      <div class="icon">
        <img />
      </div>
      <div class="txt">
        <p>Automatic Allocation of New Devices is On.</p>
      </div>
    </div>

    <div class="table inner-table table-responsive">
      <table class=" summary-table">
        <tbody>
          <tr>
            <td>Allocated:</td>
            <td><a routerLink="/allocate-device">{{ totalUnitCount }} units</a><br />to {{
              getAutoAllocatePortal }}</td>
          </tr>
          <tr>
            <td>On Since:</td>
            <td>{{formattedDate}} <br /> ({{minutesDiff}})</td>
            <!-- <td>
              Today, 8.33am <br />
              (49 minutes)
            </td> -->
          </tr>
          <tr>
            <td>Started By:</td>
            <td>{{ getLoggedInUserName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>



<section>
  <div class="container">
    <div class="bottom-btn">
      <a href="javascript:void(0)" (click)="toggleVisibility()">
        <div class="stop-btn">
          <div class="btn-icon">
            <img src="../../../assets/icons/stop.svg" alt="Invalid img" />
          </div>
          <div class="btn-text">
            <p>STOP AUTOMATICALLY ALLOCATING NEW DEVICES</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal" id="myModal" [hidden]="!isShown">
  <div class="modal-content">
    <p>Stopping Automatic Allocation of New TracNet Devices</p>
    <div class="modal-buttons">
      <a href="javascript:void(0)" (click)="confirmAction()">
        <div class="confirm-btn">
          <div class="btn-text">
            <p>CONFIRM</p>
            <div class="btn-icon">
              <img src="../../../assets/icons/Tick.svg" alt="" />
            </div>
          </div>
        </div>
      </a>
      <a href="javascript:void(0)" (click)="closeModal()">
        <div class="cancel-btn">
          <div class="btn-text">
            <p>CANCEL</p>
            <div class="btn-icon">
              <img src="../../../assets/icons/close.svg" alt="" />
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>