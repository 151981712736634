import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.baseUrl;
  router: any;
  constructor(private http: HttpClient) {

  }

  postRequest(endpoint: string, data: any): Observable<any> {
    return this.http.post(this.baseUrl + endpoint, data)
  }

  putRequest(endpoint: string, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(this.baseUrl + endpoint, data, { headers: headers })
      .pipe(
        catchError(error => {
          console.error('Error in putRequest:', error);
          throw error;
        })
      );
  }


  getRequest(endpoint: string): Observable<any> {
    return this.http.get(this.baseUrl + endpoint)
  }

  deleteRequest(endpoint: string): Observable<any> {
    return this.http.delete(this.baseUrl + endpoint)
  }

}
